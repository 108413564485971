<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-select v-model="query.disType" filterable clearable placeholder="经销商维度" class="filter-item" style="width: 120px" @change="toQuery">
        <el-option v-for="(v,k) in disTypes" :key="k" :label="v" :value="k" />
      </el-select>
      <el-select v-model="query.goodsType" filterable clearable placeholder="商品维度" class="filter-item" style="width: 120px" @change="toQuery">
        <el-option v-for="(v,k) in goodsTypes" :key="k" :label="v" :value="k" />
      </el-select>
      <el-select v-model="query.enabled" filterable clearable placeholder="启用状态" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="启用" :value="true" />
        <el-option label="停用" :value="false" />
      </el-select>
      <el-input v-model="query.name" clearable placeholder="输入名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <!--表单组件-->
    <eForm ref="form" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="name" label="名称" min-width="150" />
      <el-table-column prop="discount" label="折扣" width="70" :formatter="v=>{return v.discount + '%'}" />
      <el-table-column prop="distributorType" label="经销商维度" width="100" :formatter="v=>{return disTypes[v.distributorType]}" />
      <el-table-column prop="goodsType" label="商品维度" width="100" :formatter="v=>{return goodsTypes[v.goodsType]}" />
      <el-table-column prop="info" label="描述" min-width="200" />
      <el-table-column label="开始日期" :formatter="r => { return new Date(r.beginTime).format('yyyy/MM/dd'); }" width="100" />
      <el-table-column label="结束日期" :formatter="r => { return new Date(r.endTime).format('yyyy/MM/dd'); }" width="100" />
      <el-table-column label="启用状态" width="70">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enabled" size="mini" @change="changeEnabled(scope.row, $event)" />
        </template>
      </el-table-column>
      <el-table-column label="创建时间" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd hh:mm:ss'); }" width="150" />
      <el-table-column width="90px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <div v-if="!scope.row.enabled">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
          <div v-else>
            <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
          </div>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { enable, del } from "@/api/distributorDiscount";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      query: {
        name: null,
      },
      disTypes: {
        all: "全部",
        dis: "经销商",
        disGroup: "经销商组",
        disLevel: "经销商类型",
      },
      goodsTypes: {
        all: "全部",
        goods: "商品",
        series: "商品系列",
        category: "商品类型",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributorDiscount";
      const sort = "createAt,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          //this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    view(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)), true);
    },
    changeEnabled(data, v) {
      enable(data.id, { enabled: v })
        .then((_) => {
          this.$notify({
            title: "操作成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          v.enabled = !v;
        });
    },
  },
};
</script>