<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增经销商折扣' : '编辑经销商折扣'" @closed="form = null" width="940px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px" label-suffix=":" v-if="form">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" maxlength="30" :readonly="disabled" />
      </el-form-item>
      <div class="h wrap">
        <el-form-item label="折扣率" prop="discount">
          <percent-input v-model="form.discount" size="mini" :base="1" :precision="0" :max="200" style="width: 140px;" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="开始时间" prop="beginTime">
          <el-date-picker v-model="form.beginTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="00:00:00" :readonly="disabled" style="width: 200px;" />
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker v-model="form.endTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="23:59:59" :readonly="disabled" style="width: 200px;" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="经销商维度" prop="distributorType">
          <quick-select v-model="form.distributorType" :options="disTypes" display-field="label" @change="handleDisTypeChange" :disabled="disabled" style="width: 140px;" />
        </el-form-item>
        <el-form-item prop="disTarget" label="经销商" key="_target_type_1" class="flex" v-if="form.distributorType=='dis'">
          <datagrid-picker :disabled="disabled" v-model="form.disTarget" multiple submit url="api/distributor" value-field="enterpriseId" query-value-field="ids" :query-define="distributorQueryDefine" :show-index="false" placeholder="请选择经销商" style="width: 100%;">
            <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
              <el-input v-if="$erp.enable()" v-model="distributorQueryDefine.erpId" :validate-event="false" clearable placeholder="ERP编号搜索" @clear="scope.query" @keyup.enter.native="toQuery" style="width: 130px;" />
              <el-input :maxlength="20" v-model="distributorQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;" />
              <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
            </div>
            <el-table-column prop="erpId" label="ERP编码" min-width="130" v-if="$erp.enable()" />
            <el-table-column prop="name" label="经销商" min-width="160" />
            <el-table-column label="所在地区" min-width="200" :formatter="v=>{return v.provinceName?(v.provinceName+'/'+v.cityName + '/' + v.districtName):''}" />
          </datagrid-picker>
        </el-form-item>
        <el-form-item prop="disTarget" label="经销商类型" key="_target_type_2" class="flex" v-else-if="form.distributorType=='disLevel'">
          <quick-select v-model="form.disTarget" url="api/distributorLeve" multiple filterable clearable placeholder="请选择经销商类型" style="width: 100%;" :disabled="disabled" />
        </el-form-item>
        <el-form-item prop="disTarget" label="经销商组" key="_target_type_3" class="flex" v-else-if="form.distributorType=='disGroup'">
          <quick-select v-model="form.disTarget" url="api/distributorGroup" multiple placeholder="请选择经销商组" filterable style="width: 100%;" :disabled="disabled" />
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item label="商品维度" prop="goodsType">
          <quick-select v-model="form.goodsType" :options="goodsTypes" display-field="label" @change="handleGoodsTypeChange" :disabled="disabled" style="width: 140px;" />
        </el-form-item>
        <el-form-item prop="goodsTarget" label="商品" key="_goods_target_type_1" class="flex" v-if="form.goodsType=='goods'">
          <datagrid-picker :disabled="disabled" v-model="form.goodsTarget" multiple submit dialog dialog-title="选择商品" dialog-width="1080px" url="api/goods/sku" query-value-field="skuIds" :query-define="goodsQueryDefine" :show-index="false" placeholder="请选择商品" style="width: 100%;">
            <div class="head-container" slot="banner" slot-scope="scope">
              <quick-select url="api/brand" v-model="goodsQueryDefine.brandId" filterable placeholder="品牌" class="filter-item" clearable style="width: 160px;" />
              <quick-cascader class="filter-item" v-model="goodsQueryDefine.seriesId" url="api/series/tree" :params="{enabled: true}" root-key check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" />
              <quick-cascader class="filter-item" v-model="goodsQueryDefine.categoryId" url="api/category/tree" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 160px;" />
              <el-input v-model="goodsQueryDefine.keywords" clearable placeholder="商品编码/ERP编码/商品名称" style="width: 210px;" class="filter-item" @keyup.enter.native="scope.query" />
              <el-input v-model="goodsQueryDefine.specs" clearable placeholder="商品规格" style="width: 160px;" class="filter-item" @keyup.enter.native="scope.query" />
              <el-button type="success" icon="el-icon-search" class="filter-item" @click.stop="scope.query">搜索</el-button>
            </div>
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="name" label="商品名称" show-overflow-tooltip min-width="240" />
            <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="160" :formatter="$goodsSpecConvert" />
          </datagrid-picker>
        </el-form-item>
        <el-form-item prop="goodsTarget" label="商品分类" key="_goods_target_type_2" class="flex" v-else-if="form.goodsType=='category'">
          <quick-cascader v-model="form.goodsTarget" url="api/category/tree" parent-field="pid" multiple placeholder="请选择商品分类" filterable style="width: 100%;" :disabled="disabled" />
        </el-form-item>
        <el-form-item prop="goodsTarget" label="商品系列" key="_goods_target_type_3" class="flex" v-else-if="form.goodsType=='series'">
          <quick-cascader v-model="form.goodsTarget" url="api/series/tree" :params="{enabled: true}" parent-field="pid" multiple placeholder="请选择商品系列" filterable style="width: 100%;" :disabled="disabled" />
        </el-form-item>
      </div>
      <el-form-item label="启用状态">
        <el-switch v-model="form.enabled" size="mini" :disabled="disabled" />
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="form.info" maxlength="100" :readonly="disabled" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit" v-if="!disabled">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit, getById } from "@/api/distributorDiscount";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      visible: false,
      disabled: false,
      form: null,
      distributorQueryDefine: {
        name: null,
        erpId: null
      },
      goodsQueryDefine: {
        brandId: null,
        categoryId: null,
        seriesId: null,
        keywords: null,
        specs: null
      },
      disTypes: [
        { id: "all", label: "全部" },
        { id: "dis", label: "经销商" },
        { id: "disGroup", label: "经销商组" },
        { id: "disLevel", label: "经销商类型" }
      ],
      goodsTypes: [
        { id: "all", label: "全部" },
        { id: "goods", label: "商品" },
        { id: "series", label: "商品系列" },
        { id: "category", label: "商品类型" }
      ],
      rules: {
        name: [{ required: true, message: "请输入折扣名称", trigger: "blur" }],
        discount: [{ required: true, message: "折扣不能为空", trigger: "blur" }],
        beginTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" }
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" }
        ],
        distributorType: [
          { required: true, message: "请选择经销商维度", trigger: "blur" }
        ],
        goodsType: [
          { required: true, message: "请选择商品维度", trigger: "blur" }
        ],
        disTarget: [{ required: true, message: "请选择", trigger: "blur" }],
        goodsTarget: [{ required: true, message: "请选择", trigger: "blur" }]
      }
    };
  },

  methods: {
    handleDisTypeChange() {
      this.form.disTarget = [];
    },
    handleGoodsTypeChange() {
      this.form.goodsTarget = [];
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      let data = Object.assign({}, this.form);
      data.disTarget = data.disTarget.join(",");
      data.goodsTarget = data.goodsTarget.join(",");
      add(data)
        .then(res => {
          this.$notify({
            title: "添加经销商折扣成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    doEdit() {
      let data = Object.assign({}, this.form);
      data.disTarget = data.disTarget.join(",");
      data.goodsTarget = data.goodsTarget.join(",");
      edit(data)
        .then(res => {
          this.$notify({
            title: "编辑经销商折扣成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    resetForm(form, disabled) {
      this.disabled = disabled;
      if (form) {
        getById(form.id).then(res => {
          if (res.disTarget) {
            res.disTarget = (res.disTarget || "").split(",").map(Number);
          } else {
            res.disTarget = [];
          }
          if (res.goodsTarget) {
            res.goodsTarget = (res.goodsTarget || "").split(",");
          } else {
            res.goodsTarget = [];
          }
          this.form = res;
        });
      } else {
        this.form = {
          name: "",
          info: "",
          distributorType: "all",
          goodsType: "all",
          disTarget: [],
          goodsTarget: [],
          discount:100,
        };
      }
      this.dialog = true;
    }
  }
};
</script>
